/* tslint:disable */
/* eslint-disable */
/**
 * Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileLocationAllOf } from './FileLocationAllOf';
import {
    FileLocationAllOfFromJSON,
    FileLocationAllOfFromJSONTyped,
    FileLocationAllOfToJSON,
} from './FileLocationAllOf';
import type { FileLocationCreate } from './FileLocationCreate';
import {
    FileLocationCreateFromJSON,
    FileLocationCreateFromJSONTyped,
    FileLocationCreateToJSON,
} from './FileLocationCreate';
import type { FileStatus } from './FileStatus';
import {
    FileStatusFromJSON,
    FileStatusFromJSONTyped,
    FileStatusToJSON,
} from './FileStatus';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';

/**
 * 
 * @export
 * @interface FileLocation
 */
export interface FileLocation {
    /**
     * 
     * @type {Metadata}
     * @memberof FileLocation
     */
    metadata: Metadata;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileLocation
     */
    path: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FileLocation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FileLocation
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof FileLocation
     */
    mimeType: string;
    /**
     * 
     * @type {number}
     * @memberof FileLocation
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof FileLocation
     */
    checksum?: string;
    /**
     * 
     * @type {FileStatus}
     * @memberof FileLocation
     */
    fileStatus: FileStatus;
    /**
     * 
     * @type {number}
     * @memberof FileLocation
     */
    uploadedSize: number;
    /**
     * 
     * @type {string}
     * @memberof FileLocation
     */
    uploadToken?: string;
    /**
     * 
     * @type {string}
     * @memberof FileLocation
     */
    error?: string;
}

/**
 * Check if a given object implements the FileLocation interface.
 */
export function instanceOfFileLocation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "mimeType" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "fileStatus" in value;
    isInstance = isInstance && "uploadedSize" in value;

    return isInstance;
}

export function FileLocationFromJSON(json: any): FileLocation {
    return FileLocationFromJSONTyped(json, false);
}

export function FileLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'path': json['path'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'mimeType': json['mimeType'],
        'size': json['size'],
        'checksum': !exists(json, 'checksum') ? undefined : json['checksum'],
        'fileStatus': FileStatusFromJSON(json['fileStatus']),
        'uploadedSize': json['uploadedSize'],
        'uploadToken': !exists(json, 'uploadToken') ? undefined : json['uploadToken'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function FileLocationToJSON(value?: FileLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'path': value.path,
        'description': value.description,
        'type': value.type,
        'mimeType': value.mimeType,
        'size': value.size,
        'checksum': value.checksum,
        'fileStatus': FileStatusToJSON(value.fileStatus),
        'uploadedSize': value.uploadedSize,
        'uploadToken': value.uploadToken,
        'error': value.error,
    };
}

