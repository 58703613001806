/* tslint:disable */
/* eslint-disable */
/**
 * Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileLocationCopy } from './FileLocationCopy';
import {
    FileLocationCopyFromJSON,
    FileLocationCopyFromJSONTyped,
    FileLocationCopyToJSON,
} from './FileLocationCopy';
import type { FileLocationUpdateAllOf } from './FileLocationUpdateAllOf';
import {
    FileLocationUpdateAllOfFromJSON,
    FileLocationUpdateAllOfFromJSONTyped,
    FileLocationUpdateAllOfToJSON,
} from './FileLocationUpdateAllOf';
import type { MetadataUpdate } from './MetadataUpdate';
import {
    MetadataUpdateFromJSON,
    MetadataUpdateFromJSONTyped,
    MetadataUpdateToJSON,
} from './MetadataUpdate';

/**
 * 
 * @export
 * @interface FileLocationUpdate
 */
export interface FileLocationUpdate {
    /**
     * 
     * @type {MetadataUpdate}
     * @memberof FileLocationUpdate
     */
    metadata: MetadataUpdate;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileLocationUpdate
     */
    path?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FileLocationUpdate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FileLocationUpdate
     */
    type?: string;
}

/**
 * Check if a given object implements the FileLocationUpdate interface.
 */
export function instanceOfFileLocationUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function FileLocationUpdateFromJSON(json: any): FileLocationUpdate {
    return FileLocationUpdateFromJSONTyped(json, false);
}

export function FileLocationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileLocationUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataUpdateFromJSON(json['metadata']),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function FileLocationUpdateToJSON(value?: FileLocationUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataUpdateToJSON(value.metadata),
        'path': value.path,
        'description': value.description,
        'type': value.type,
    };
}

