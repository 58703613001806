/* tslint:disable */
/* eslint-disable */
/**
 * Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalId } from './ExternalId';
import {
    ExternalIdFromJSON,
    ExternalIdFromJSONTyped,
    ExternalIdToJSON,
} from './ExternalId';

/**
 * 
 * @export
 * @interface MetadataCopy
 */
export interface MetadataCopy {
    /**
     * 
     * @type {string}
     * @memberof MetadataCopy
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataCopy
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataCopy
     */
    creator?: string;
    /**
     * 
     * @type {Array<ExternalId>}
     * @memberof MetadataCopy
     */
    externalIds?: Array<ExternalId>;
    /**
     * 
     * @type {number}
     * @memberof MetadataCopy
     */
    copyNumber: number;
    /**
     * 
     * @type {string}
     * @memberof MetadataCopy
     */
    copiedFrom: string;
}

/**
 * Check if a given object implements the MetadataCopy interface.
 */
export function instanceOfMetadataCopy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "copyNumber" in value;
    isInstance = isInstance && "copiedFrom" in value;

    return isInstance;
}

export function MetadataCopyFromJSON(json: any): MetadataCopy {
    return MetadataCopyFromJSONTyped(json, false);
}

export function MetadataCopyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataCopy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdFromJSON)),
        'copyNumber': json['copyNumber'],
        'copiedFrom': json['copiedFrom'],
    };
}

export function MetadataCopyToJSON(value?: MetadataCopy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'creator': value.creator,
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdToJSON)),
        'copyNumber': value.copyNumber,
        'copiedFrom': value.copiedFrom,
    };
}

