/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParsedParcel } from './ParsedParcel';
import {
    ParsedParcelFromJSON,
    ParsedParcelFromJSONTyped,
    ParsedParcelToJSON,
} from './ParsedParcel';

/**
 * 
 * @export
 * @interface ParsedDocument
 */
export interface ParsedDocument {
    /**
     * 
     * @type {string}
     * @memberof ParsedDocument
     */
    file: string;
    /**
     * 
     * @type {Array<ParsedParcel>}
     * @memberof ParsedDocument
     */
    parcels: Array<ParsedParcel>;
}

/**
 * Check if a given object implements the ParsedDocument interface.
 */
export function instanceOfParsedDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "parcels" in value;

    return isInstance;
}

export function ParsedDocumentFromJSON(json: any): ParsedDocument {
    return ParsedDocumentFromJSONTyped(json, false);
}

export function ParsedDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParsedDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file': json['file'],
        'parcels': ((json['parcels'] as Array<any>).map(ParsedParcelFromJSON)),
    };
}

export function ParsedDocumentToJSON(value?: ParsedDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': value.file,
        'parcels': ((value.parcels as Array<any>).map(ParsedParcelToJSON)),
    };
}

